label.login {
    margin-bottom: 0;
}
.checkboxCustom {
    margin-top: 0.4rem;
}
.checkboxCustom input {
    width: 0.1rem;
}

.checkboxCustom .labelWrapper {
    padding-left: 0;
}

.checkboxCustom label {
    padding-left: 3rem;
    line-height: 1.3rem;;
}

.checkboxCustom label:before {
    width: 2rem;
    height: 2rem;
    margin-left: 1.5rem;
}

.checkboxCustom label:after {
    width: 2rem;
    height: 2rem;
    left: 0.1rem;
}

.wizzardTitle {
    font-weight: 300;
    line-height: 1.3rem;
    font-size: 0.8rem;
    padding: 0.3rem 1rem;
    border: 1px solid  #1d4360;
    border-radius: 0.25rem;
}
.recaptcha {
    margin: 0.5rem 0px;
}

.frameBorder {
    border: 1px solid #dee2e6;
    border-top: none;
}