.root {
    border: 2px solid #e33c25;
    border-radius: 0.25rem;
    line-height: 1.5rem;
    padding: 0.5rem 0.75rem;
}

.root p.title {
    color: #e33c25;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
}

.root p.message {
    font-size: 0.8rem;
    margin: 0.25rem 0;
    line-height:1.1rem;
}